import { Route, Routes } from "react-router-dom";
import HomePage from 'pages/home';

const RoutePath = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
    </Routes>
  )
}

export default RoutePath